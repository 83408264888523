import { AxiosResponse } from 'axios';
import { axiosIntance } from '../../../config/ApiConfig';
import {
  Provider,
  ResponseProvider,
} from '../../../types/provider/Provider';

export const providerDao = {
  list: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<ResponseProvider>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }

    if (!page) {
      page = 1;
    }
    if (!limit) {
      limit = 20;
    }
    return axiosIntance.get(
      `/provider/list?page=${page}&limit=${limit}&accountId=${accountId}${query}${filter}`
    );
  },
  show: (
    id: string,
    accountId: string
  ): Promise<AxiosResponse<Provider>> => {
    return axiosIntance.get(`/provider/${accountId}/${id}`);
  },
};
