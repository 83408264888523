import { AxiosResponse } from 'axios';
import { axiosIntance } from '../../../config/ApiConfig';

export const OrdersDao = {
  list: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null,
    filter: string | null,
    queryType?: string
  ) => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }
    let specialQuery = '';
    if (queryType) {
      specialQuery = `&${specialQuery}`;
    } else {
      specialQuery = '';
    }

    if (!page) {
      page = 1;
    }
    if (!limit) {
      limit = 20;
    }
    return axiosIntance.get(
      `/orders?page=${page}&limit=${limit}&account=${accountId}${query}${filter}${specialQuery}`
    );
  },
  show: (id: string) => {
    return axiosIntance.get(`/orders/${id}`);
  },
  getList: (accountId: string) => {
    return axiosIntance.get(`/orders/list?account=${accountId}`);
  },
  getAccountsList: (accountId: string) => {
    return axiosIntance.get(`/${accountId}/accounts/list`);
  },
  panel: () => {
    return axiosIntance.get(`/orders/kitchen/panel`);
  },
  getExcel: (
    accountId: string,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<Blob>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }
    return axiosIntance.get(
      `/orders/pdf?account=${accountId}${query}${filter}`,
      {
        responseType: 'blob', // important
      }
    );
  },
  getExcelInvoice: (
    accountId: string,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<Blob>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }
    return axiosIntance.get(
      `/orders/invoices/export?account=${accountId}${query}${filter}`
    );
  },
  listOrderPayments: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null,
    filter: string | null
  ) => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }

    if (!page) {
      page = 1;
    }
    if (!limit) {
      limit = 20;
    }
    return axiosIntance.get(
      `/orders/payments?page=${page}&limit=${limit}&accountId=${accountId}${query}${filter}`
    );
  },
  listOrderInvoices: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null,
    filter: string | null
  ) => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }

    if (!page) {
      page = 1;
    }
    if (!limit) {
      limit = 20;
    }
    return axiosIntance.get(
      `/orders/invoices?page=${page}&limit=${limit}&account=${accountId}${query}${filter}`
    );
  },

  getCustomers: (
    accountId: string,
    documentId: string | number,
    documentType: string
  ) => {
    return axiosIntance.get(
      `/orders/customer?account=${accountId}&documentId=${documentId}&documentType=${documentType}`
    );
  },
};
