import AsyncStorage from '@react-native-async-storage/async-storage';

import {
  PaymentOrderStatus,
  StatusOrder,
  TableStatus,
  TypePayment,
  CardType,
  TypeDocumentIdSpanish,
  TypeDocumentIdSpanishUpperCase,
} from './enums';
import { statusColors } from '../stylesheets/variables';
import { Food } from '../types/foods/Food';
import { FoodCategory } from '../types/foodCategories/FoodCategory';
import { TableBasic } from '../types/tables/Tables';
import { Order } from '../types/orders/Orders';
import { MatchTable, PaymentMethod } from '../types/orders/interfaces';
import { UserAuth } from '../types/users/User';
import { FoodTimestamp } from '../types/checkIn/interface';
import { ElectronicInvoice } from '../types/orders/oldInterfaces';

export const truncateString = (str: string, num: number) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + '...';
};

interface FinancialFormatProps {
  amount: number;
  format?: string;
  // style?: keyof Intl.NumberFormatOptionsStyleRegistry
  style?: 'currency' | 'percent' | 'decimal';
  currency?: string;
  maximumFractionDigits?: number;
}

export const financialFormat = ({
  amount,
  format = 'es-CR',
  style = 'currency',
  currency = 'CRC',
  maximumFractionDigits = 2,
}: FinancialFormatProps) => {
  return new Intl.NumberFormat(format, {
    style,
    currency,
    maximumFractionDigits,
  }).format(amount ?? 0);
};

export const roundNumber = (value: number, decimals = 3) => {
  const aux = value.toFixed(decimals);
  return Number(aux);
};

export const getDiscountedPrice = (
  totalPrice: number,
  discountPercent: number
) => {
  const discountedCost = calculateDiscount(totalPrice, discountPercent);
  return discountedCost;
};

export const getFee = (price: number, porcent: number) => {
  const aux = porcent / 100;
  return porcent > 0 ? price * aux : 0;
};

export const calculateDiscount = (price: number, discount: number) => {
  const porcent = discount / 100;
  return discount > 0 ? price - price * porcent : price;
};

export const calculateTax = (price: number, tax: number) => {
  const porcent = tax / 100;
  return tax > 0 ? price + price * porcent : price;
};

export const getOriginalPrice = (total: number, discountPercent: number) => {
  if (discountPercent > 0) {
    const reduced = 1 - discountPercent / 100;
    if (reduced <= 0) return 0;
    return total / reduced;
  } else {
    return total;
  }
};

export const monthsArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const sortMonthsArray = (array: any[]) => {
  //!NOTA: Para usar esta función se requiere que el array tenga como propiedad en cada objeto "month", que sea de 12 elementos y que esten en ingles los meses
  const newArray = array?.sort((a, b) => {
    return monthsArray.indexOf(a.month) - monthsArray.indexOf(b.month);
  });
  return newArray;
};

export const suprimeDuplicateElementsArray = (array: any[]) => {
  let newArray = array?.filter((item, index) => {
    return (
      index ===
      array.findIndex((obj) => {
        return JSON.stringify(obj) === JSON.stringify(item);
      })
    );
  });
  return newArray;
};

export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

//TODO: Para obtener un color dependiendo de un status especifico

export const getOrderColor = (status: string) => {
  switch (status) {
    case StatusOrder.COMPLETED: {
      return statusColors.completed;
    }
    case StatusOrder.INPREPARATION: {
      return statusColors.inProgress;
    }
    case StatusOrder.GENERATED: {
      return statusColors.generated;
    }
    case StatusOrder.SAVED: {
      return statusColors.saved;
    }
    default: {
      return statusColors.cancelled;
    }
  }
};

export const getPaymentColor = (status: string) => {
  switch (status) {
    case PaymentOrderStatus.COMPLETED: {
      return statusColors.paid;
    }
    default: {
      return statusColors.pending;
    }
  }
};

export const getTableColor = (status: string) => {
  switch (status) {
    case TableStatus.AVAILABLE: {
      return statusColors.available;
    }
    case TableStatus.RESERVED: {
      return statusColors.reserved;
    }
    default: {
      return statusColors.busy;
    }
  }
};

export const formatDate = (date: Date, format: string) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatNumber = (num: number | string) => {
  // Elimina cualquier carácter que no sea un dígito o un punto decimal
  const cleaned = ('' + num).replace(/[^\d.]/g, '');

  // Divide el número en parte entera y decimal
  const [integerPart, decimalPart] = cleaned.split('.');

  // Formatea la parte entera con comas como separadores de miles
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Si hay una parte decimal, la agrega al número formateado
  const formatted =
    decimalPart !== undefined
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;

  return formatted;
};

export const validateInputAmount = (input: string | number) => {
  const text = String(input);
  let value = text?.replace(',', '');
  const valueArray = value?.split('');
  const aux: string[] = [];
  valueArray?.forEach((e) => {
    if (!containLetters(e) && !containSpecialCharacterExceptDotAndComma(e)) {
      aux.push(e);
    }
  });
  value = aux?.join('');
  if (value[value.length - 1] === '.' || value[value.length - 1] === ',') {
    value = value.slice(0, -1);
  }
  return value;
};

export const matchCategory = (data: Food[], categories: FoodCategory[]) => {
  const IDCategories = categories?.map((e) => e._id);
  const NameCategories = categories?.map((e) => {
    return { name: e.name, id: e._id, isFavorite: e.isFavorite };
  });
  let array: { category: string; data: Food[] }[] = [];
  let noCategory: Food[] = [];

  for (let i = 0; i < NameCategories.length; i++) {
    let aux = [];
    for (let j = 0; j < data.length; j++) {
      const find = data[j].foodCategoryId?.find(
        (e) => e === NameCategories[i].id
      );
      if (find) {
        aux.push(data[j]);
      } else {
        let b = [];
        for (let k = 0; k < data[j].foodCategoryId.length; k++) {
          let auxK = IDCategories.includes(data[j].foodCategoryId[k]);
          b.push(auxK);
        }
        let verify = b.includes(true);
        if (!verify) {
          noCategory.push(data[j]);
        }
      }
    }
    const element = {
      category: NameCategories[i].name,
      data: aux,
      isFavorite: NameCategories[i].isFavorite,
    };
    array.push(element);
  }
  noCategory = suprimeDuplicateElementsArray(noCategory);

  const favoriteCategories = array.filter(
    (category: any) => category.isFavorite === 'true'
  );

  const nonFavoriteCategories = array.filter(
    (category: any) => category.isFavorite !== 'true'
  );

  favoriteCategories.sort((a, b) => a.category.localeCompare(b.category));

  nonFavoriteCategories.sort((a, b) => a.category.localeCompare(b.category));

  const sortedArray = [...favoriteCategories, ...nonFavoriteCategories];

  return [...sortedArray, { category: 'Sin Categoría', data: noCategory }];
};

export const matchTable = (orders: Order[], tablesParams: TableBasic[]) => {
  const aux: MatchTable[] = [];
  let tables = [...tablesParams];
  tables = tables?.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    } else {
      return 0;
    }
  });
  tables?.forEach((table) => {
    let ord: Order[] = [];
    orders?.forEach((order) => {
      if (
        table._id === order.tableId._id &&
        order.paymentStatus === PaymentOrderStatus.PENDING
      ) {
        ord.push(order);
      }
    });
    const obj = {
      table: table,
      order: ord,
    };
    aux.push(obj);
  });
  return aux;
};

export const getUserData = async () => {
  const data = await AsyncStorage.getItem('user');
  if (data) {
    const user: UserAuth = JSON.parse(data);
    return user;
  } else {
    return null;
  }
};

export const isURL = (str: string) => {
  const urlRegex = new RegExp(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i);
  return urlRegex.test(str);
};

export const containComma = (value: string | number) => {
  const val = String(value);
  const commaRegex = new RegExp(/.*,.*/);
  return commaRegex.test(val);
};

export const documentCiNite = (input: string | number) => {
  const val = String(input);
  const urlRegex = new RegExp(/^[1-9]\d{8}$/);
  return urlRegex.test(val);
};

export const documentCj = (input: string | number) => {
  const val = String(input);
  const urlRegex = new RegExp(/^[1-9]\d{9}$/);
  return urlRegex.test(val);
};

export const documentDimex = (input: string | number) => {
  const val = String(input);
  const urlRegex = new RegExp(/^[1-9]\d{10,11}$/);
  return urlRegex.test(val);
};

export const containLetters = (input: string | number) => {
  const val = String(input);
  const urlRegex = new RegExp(/[a-zA-Z]/);
  return urlRegex.test(val);
};

export const containSpecialCharacterExceptDotAndComma = (
  input: string | number
) => {
  const val = String(input);
  const urlRegex = new RegExp(/[^\w\s.,]/);
  return urlRegex.test(val);
};

const removeTimeZoneDate = (date: Date) => {
  const timeZone = new Date(date).getTimezoneOffset();
  const UTC = -timeZone / 60;
  return new Date(new Date(date).setHours(new Date(date).getHours() + UTC));
};

export const getDiffDays = (init: Date, end: Date) => {
  const mls = 1000 * 60 * 60 * 24;
  const initDate = removeTimeZoneDate(init);
  const endDate = removeTimeZoneDate(end);
  const date = initDate.getTime() - endDate.getTime();
  const result = Math.floor(date / mls);
  // console.log(`${initDate} - ${endDate} = ${result}`);
  return result;
};

export const sumArray = (array: object[], key: string): number => {
  return array?.reduce((prev, val) => {
    //@ts-ignore
    return prev + val[key] ?? 0;
  }, 0);
};

export const getAmountToPay = (
  food: Food,
  field:
    | 'total'
    | 'subTotal'
    | 'discountFee'
    | 'serviceFee'
    | 'otherFee'
    | 'vatFee'
) => {
  const unitTotal = roundNumber(food[field] / food.quantity);
  return unitTotal * food.quantityToPay;
};

export const sumFoodsQuantityToPay = (
  foods: Food[],
  field:
    | 'total'
    | 'subTotal'
    | 'discountFee'
    | 'serviceFee'
    | 'otherFee'
    | 'vatFee'
) => {
  return foods?.reduce((prev, val) => {
    return prev + getAmountToPay(val, field);
  }, 0);
};

export const sumOrdersPrice = (
  orders: Order[],
  field:
    | 'total'
    | 'subTotal'
    | 'discountFee'
    | 'serviceFee'
    | 'otherFee'
    | 'vatFee'
) => {
  return orders?.reduce((prev, val) => {
    return prev + sumFoodsQuantityToPay(val.foods, field);
  }, 0);
};

export const sliceTimeStampIdFirst = (timeStampId: number) => {
  const timeStampIdString = String(timeStampId);
  const timeStampIdSliced = timeStampIdString.substring(
    0,
    timeStampIdString.length - 4
  );
  return Number(timeStampIdSliced);
};

export const sliceTimeStampIdLast = (timeStampId: number | string) => {
  const timeStampIdString = String(timeStampId);
  const timeStampIdSliced = timeStampIdString.substring(
    timeStampIdString.length - 4,
    timeStampIdString.length
  );
  return Number(timeStampIdSliced);
};

export const getPaymentMethodName = (row: PaymentMethod) => {
  switch (row.method) {
    case TypePayment.CARD: {
      return 'Tarjeta';
    }
    case TypePayment.CASH: {
      return 'Efectivo';
    }
    case TypePayment.SINPE_PAY: {
      return 'Transferencia';
    }
    case TypePayment.PENDING: {
      return 'Pendiente';
    }
    default: {
      return '';
    }
  }
};

export const getCardName = (row: PaymentMethod) => {
  switch (row.type) {
    case CardType.AMERICAN_EXPRESS: {
      return 'American Express';
    }
    case CardType.MASTER_CARD: {
      return 'Master Card';
    }
    case CardType.VISA: {
      return 'Visa';
    }
    case CardType.OTHER: {
      return 'Otro';
    }
    default: {
      return 'Otro';
    }
  }
};

export const getNewPriceQtyToPay = (
  price: number,
  qty: number,
  newQty: number
) => {
  if (qty > 0) {
    return price - (price / qty) * (qty - newQty);
  } else {
    return 0;
  }
};

export const findQtyByFoodTimestampId = (
  arrayFoodTimestamp: FoodTimestamp[],
  foodTimestampId: number | string
) => {
  const item = arrayFoodTimestamp?.find(
    (obj) => obj.foodTimestampId == foodTimestampId
  );
  return item ? item.qty : null;
};

export const clearSavedAccountsStorage = async () => {
  await AsyncStorage.setItem('savedAccounts', JSON.stringify([]));
};

export const clearSavedOrdersStorage = async () => {
  await AsyncStorage.setItem('savedOrder', JSON.stringify([]));
};

export const restartApp = () => {
  // Recargar la página actual
  window.location.reload();
};

export const validateDocumentType = (electronicInvoice: ElectronicInvoice) => {
  if (
    electronicInvoice &&
    electronicInvoice.documentType &&
    electronicInvoice.documentId
  ) {
    if (
      electronicInvoice.documentType === TypeDocumentIdSpanish.CI ||
      electronicInvoice.documentType === TypeDocumentIdSpanish.NITE
    ) {
      const regex = new RegExp(/^[1-9]\d{8}$/);
      return regex.test(electronicInvoice.documentId);
    } else if (electronicInvoice.documentType === TypeDocumentIdSpanish.CJ) {
      const regex = new RegExp(/^[1-9]\d{9}$/);
      return regex.test(electronicInvoice.documentId);
    } else if (electronicInvoice.documentType === TypeDocumentIdSpanish.DIMEX) {
      const regex = new RegExp(/^[1-9]\d{10,11}$/);
      return regex.test(electronicInvoice.documentId);
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const typeDocumentFormatter = (type: string) => {
  switch (type) {
    case TypeDocumentIdSpanish.CI: {
      return TypeDocumentIdSpanishUpperCase.CI;
    }
    case TypeDocumentIdSpanish.CJ: {
      return TypeDocumentIdSpanishUpperCase.CJ;
    }
    case TypeDocumentIdSpanish.DIMEX: {
      return TypeDocumentIdSpanishUpperCase.DIMEX;
    }
    case TypeDocumentIdSpanish.NITE: {
      return TypeDocumentIdSpanishUpperCase.NITE;
    }
    default: {
      return type;
    }
  }
};
