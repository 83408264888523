import { AxiosResponse } from 'axios';

import { axiosIntance } from '../../../config/ApiConfig';
import { Decrease, ResponseDecrease } from '../../../types/decrease/Decrease';

export const DecreaseDao = {
  list: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<ResponseDecrease>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }

    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }

    if (!page) {
      page = 1;
    }
    if (!limit) {
      limit = 20;
    }
    return axiosIntance.get(
      `/decrease/list?page=${page}&limit=${limit}&accountId=${accountId}${query}${filter}`
    );
  },
  show: (accountId: string, id: string): Promise<AxiosResponse<Decrease>> => {
    return axiosIntance.get(`decrease/${accountId}/${id}`);
  },
};
