import { axiosIntance } from '../../../config/ApiConfig';

export const FoodComplementsDao = {
  list: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null
  ) => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }

    if (!page) {
      page = 1;
    }
    if (!limit) {
      limit = 20;
    }
    return axiosIntance.get(
      `/foodComplements?page=${page}&limit=${limit}&account=${accountId}${query}`
    );
  },
  show: (id: string) => {
    return axiosIntance.get(`/foodComplements/${id}`);
  },
  getList: (accountId: string) => {
    return axiosIntance.get(`/foodComplements/list?account=${accountId}`);
  },
  getAccountsList: (accountId: string) => {
    return axiosIntance.get(`/${accountId}/accounts/list`);
  },
};
