import { AxiosResponse } from 'axios';
import { axiosIntance } from '../../../config/ApiConfig';
import { Purchase, ResponsePurchase } from '../../../types/shopping/Shopping';
import { DownloableFile } from '../../../types/common/interfaces';

export const PurchaseDao = {
  list: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<ResponsePurchase>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }
    if (!page) {
      page = 1;
    }
    if (!limit) {
      limit = 20;
    }
    return axiosIntance.get(
      `/purchase/list?page=${page}&limit=${limit}&accountId=${accountId}${query}${filter}`
    );
  },
  show: (id: string, accountId: string): Promise<AxiosResponse<Purchase>> => {
    return axiosIntance.get(`/purchase/${accountId}/${id}`);
  },
  getDetailPdf: (
    accountId: string,
    id: string
  ): Promise<AxiosResponse<DownloableFile>> => {
    return axiosIntance.get(`/purchase/pdf/${accountId}/${id}`);
  },
};
